<template>
  <v-container
    fluid
    id="regular-table"
  >
    <h3 class="ma-3 text-h4">{{ $t("transfer_details") }}</h3>
    <div
      class="ma-3 d-flex flex-column flex-md-row ma-4"
      style="gap: 100px"
    >
      <v-flex xs6>
        <v-divider></v-divider>
        <div class="text-h4 d-flex justify-space-between py-4 mx-4">
          <span>{{ $t("origin_hub") }}</span>
          <span class="text-h4">
            {{ transfer?.fromHub?.name ?? "-" }}
          </span>
        </div>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs6>
        <v-divider></v-divider>
        <div class="text-h4 d-flex justify-space-between py-4 mx-4">
          <span>{{ $t("destination_hub") }}</span>
          <span class="text-h4">
            {{ transfer?.toHub?.name ?? "-" }}
          </span>
        </div>
        <v-divider></v-divider>
      </v-flex>
    </div>
    <v-card class="mt-n1">
      <v-toolbar
        dark
        tabs
        flat
        color="#5E2FBE"
        class="hidden-xs-only"
      >
        <template>
          <v-tabs v-model="tabs">
            <v-tab href="#cart">
              <v-badge
                v-if="transfer.products?.length"
                color="green"
                :content="transfer.products?.length"
              >
                <v-icon>mdi-cart</v-icon>&nbsp;{{ $t("items") }}
              </v-badge>
              <span v-else> <v-icon>mdi-cart</v-icon>&nbsp;ITEM IN CART </span>
            </v-tab>
            <!-- <v-tabs-slider color="white"></v-tabs-slider> -->
          </v-tabs>
        </template>
      </v-toolbar>
    </v-card>
    <v-card-text class="px-0">
      <v-tabs-items v-model="tabs">
        <v-tab-item value="cart">
          <v-row class="mt-n1">
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-text-field
                outlined
                dense
                :label="$t('search')"
                v-model="options.search"
                class=""
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
              class="pl-0"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.shelf_id"
                :items="shelves"
                item-text="name"
                item-value="id"
                :loading="isLoadingCategories"
                @change="loadCategory(options.shelf_id, 2)"
                :label="$t('shelf')"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
              class="pl-0"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.category_parent_id"
                :items="categories"
                item-text="name"
                item-value="id"
                :loading="isLoadingCategories"
                @change="loadCategory(options.category_parent_id, 3)"
                :label="$t('category')"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
              class="pl-0"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.category_id"
                :items="sub_categories"
                item-text="name"
                item-value="id"
                :loading="isLoadingSubCategories"
                :label="$t('sub_category')"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-data-table
            :headers="cart_headers"
            item-key="name"
            :items="transfer.products"
            class="elevation-0"
            :footer-props="{ 'items-per-page-options': [5, 10] }"
          >
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>
            <template v-slot:item.total_price_ht="{ item }">
              {{
                Number(item.purchase_price_ht * item.qte_in_transfer)?.toFixed(
                  2
                )
              }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2"
                color="#C6C6C6"
                @click="openTransferItemModal(item)"
              >
                $vuetify.icons.iconEditItem
              </v-icon>
              <v-icon @click="deleteItem(item)">
                $vuetify.icons.iconDeleteItem
              </v-icon>
            </template>
          </v-data-table>
          <div class="mt-8 d-flex">
            <v-btn
              outlined
              small
              class="ml-auto primary--text"
              @click="cancel()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              small
              class="ml-2 primary"
              :disabled="!transfer.products || transfer.products <= 0"
              @click="submitTransfer()"
              >{{ $t("submit_transfer") }}</v-btn
            >
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <product-modal
      v-if="isModalOpen"
      :isModalOpen="isModalOpen"
      :transferItem="currentTransferItem"
      :toggleModal="() => toggleModal()"
    ></product-modal>
    <submit-transfer-modal
      :isSubmitTransferModalOpen="isSubmitTransferModalOpen"
      :toggleSubmitTransfer="toggleSubmitTransfer"
      :currentTransfer="transfer"
    ></submit-transfer-modal>
  </v-container>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import transferService from "@/store/services/transfer-product-service";
import categoryService from "@/store/services/category-service";
import debounce from "lodash/debounce";

export default {
  mixins: [validationRules],
  components: {
    ProductModal: () =>
      import("@/views/dashboard/rebrics/transfers/modals/ProductModal.vue"),
    SubmitTransferModal: () =>
      import(
        "@/views/dashboard/rebrics/transfers/modals/SubmitTransferModal.vue"
      ),
  },

  computed: {
    console: () => console,
    ...mapGetters({
      shelves: "categories/listShelves",
      isLoadingShelves: "categories/isLoadingCategories",
      isLoadingCategories: "categories/isLoadingCategories",
      isLoadingSubCategories: "categories/isLoadingCategories",
      isLoadingProducts: "products/isLoadingProducts",
      isLoadingProviders: "providers/isLoadingProviders",
      listProviders: "providers/activeProviders",
    }),
    transfer: {
      get() {
        return this.$store.state.transferProducts.transfer;
      },
      set(value) {
        this.$store.commit("transferProducts/TRANSFER", value);
      },
    },
  },
  data() {
    return {
      tabs: true,

      categories: [],
      sub_categories: [],
      cleanList: null,
      currentTransferItem: null,
      isModalOpen: false,
      isSubmitTransferModalOpen: false,
      is_loading_product: false,
      search_from_category: null,
      options: {},

      cart_headers: [
        { text: "Image", value: "image", align: "start" },
        {
          text: "name",
          sortable: false,
          value: "name",
        },
        { text: "Product ID", value: "generated_code" },
        { text: "barcode", value: "barcode" },
        { text: "Quantity (Units)", value: "qte_in_transfer" },
        { text: "Purchase price HT", value: "purchase_price_ht" },
        { text: "VAT %", value: "purchase_tva" },
        { text: "Total HT", value: "total_price_ht" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  watch: {
    options: {
      handler: debounce(async function () {
        const id = this.$route.params.id;
        if (id) {
          await this.$store.dispatch("transferProducts/show", {
            id,
            search: this.options.search,
            category_parent_id: this.options.category_parent_id,
            category_id: this.options.category_id,
            shelf_id: this.options.shelf_id,
          });
        }
      }, 1000),

      deep: true,
    },
  },

  methods: {
    async loadCategory(parent_id, level) {
      console.log(level, parent_id);
      if (level == 2) {
        const categories = await categoryService.list({
          parent_id,
          level,
        });
        this.categories = categories;
        this.options.category_id = null;
      } else if (level == 3) {
        const sub_categories = await categoryService.list({
          level: 3,
          parent_id,
        });
        this.sub_categories = sub_categories;
      }
    },

    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },

    async loadShelves() {
      try {
        await this.$store.dispatch("categories/listShelves");
      } catch (error) {
        if (error.response?.status == "401") {
          await this.$store.dispatch("logout");
        }
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },

    async init() {
      await this.loadShelves();
    },

    openTransferItemModal(item) {
      this.currentTransferItem = item;
      if (this.currentTransferItem.discount == null)
        this.currentTransferItem.discount = 0.0;
      this.isModalOpen = true;
    },

    deleteItem(item) {
      this.$store.dispatch("transferProducts/removeFromCart", {
        ...item,
        transfer_id: this.transfer.id,
      });
    },
    toggleSubmitTransfer() {
      this.isSubmitTransferModalOpen = !this.isSubmitTransferModalOpen;
    },

    submitTransfer() {
      this.toggleSubmitTransfer();
    },

    cancel() {
      this.$store.commit("SET_PAGE_SUBTITLE", "");
      this.$store.commit("SET_SUBTITLE_ID", "");

      this.$router.push("/transfers");
    },

    async updateTransfer(transfer) {
      try {
        if (transfer.id) {
          await this.$store.dispatch("transferProducts/update", transfer);
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
        } else {
          this.$store.dispatch("alerts/error", "Transfer doesn't exist");
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },

  async created() {
    this.$store.commit("transferProducts/TRANSFER", {});
    await this.init();
    const id = this.$route.params.id;
    if (id) {
      const transferEntity = await transferService.show({
        id,
      });
      this.currentFromHubID = transferEntity.from_hub_id;
      this.$store.commit("transferProducts/TRANSFER", transferEntity);
      this.$store.commit("SET_PAGE_SUBTITLE", transferEntity.tracking_number);
      this.$store.commit("SET_SUBTITLE_ID", "Transfer ID :");
    }
  },
};
</script>
